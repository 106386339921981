<template lang="pug">
el-form
  el-form-item(label="Сумма" required)
    el-input(v-model="amount" :formatter="amountFormatter")
  el-form-item(label="Наименование услуги" required)
    el-input(v-model="serviceName")
  el-form-item
    el-button(
      size="small"
      plain
      round
      @click="update"
    ) Обновить
</template>

<script setup lang="ts">
import type { Act } from '@/models/documents';
import { useDocumentsStore } from '@/store/documents';

const props = defineProps<{ act: Act }>();

const documentsStore = useDocumentsStore();

const amount = ref('');
const serviceName = ref('');

const emit = defineEmits(['updated']);

const update = () => {
  documentsStore.updateAct(
    props.act,
    amount.value,
    serviceName.value,
  )
    .then(() => emit('updated'));
}

const amountFormatter = (v: string) => {
  v = v.replaceAll(/^(\.)|([^\d\.])/g, '');
  v = v.replaceAll(/\.+/g, '.');

  const [i, d, ..._] = v.split('.');

  if (d) {
    v = [i, d].join('.');
  }

  if (d?.length > 2) {
    v = [i, d.substring(0, 2)].join('.');
  }

  return v;
}
</script>
