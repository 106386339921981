<template lang="pug">
el-card#documents(header="Акты и чеки")
  el-row(style="margin-bottom: 10px; gap: 20px")
    el-button(
      size="small"
      plain
      round
      @click="() => handleActCreate()"
    ) Создать акт
  el-table(:data="documentsStore.acts" v-loading="actsLoading")
    el-table-column(label="Период" v-slot="act: { row: Act }") {{ act.row.periodStart }} — {{ act.row.periodEnd }}
    el-table-column(align="center" label="Акт" v-slot="act: { row: Act }")
      el-space
        el-link(:href="act.row.actFile?.url" type="primary") скачать
        el-space.actions(v-if="!act.row.confirmed", direction="vertical")
          el-button(
            v-if="canUpdateAct(act.row)"
            size="small"
            plain
            round
            @click="() => handleActUpdate(act.row)"
          ) Править данные
          el-upload(
            v-if="!act.row.signed"
            @change="(file) => handleSignedActUpload(file, act.row.uuid)"
            :show-file-list="false"
            :auto-upload="false"
          )
            el-button(size="small" plain round) Загрузить с подписью
    el-table-column(align="center" label="Статус" v-slot="act: { row: Act }")
      el-text(:type="STATUS_COLORS[actStatus(act.row)]") {{ actStatus(act.row) }}
    el-table-column(align="center" label="Чек" v-slot="act: { row: Act }")
      el-space
        el-link(
          v-if="act.row.receiptFile"
          :href="act.row.receiptFile?.url"
          type="primary"
          style="text-align: left;"
        ) скачать
        el-upload(
          v-if="canUploadReceipt(act.row)"
          @change="(file) => handleActReceiptUpload(file, act.row.uuid)"
          :show-file-list="false"
          :auto-upload="false"
        )
          el-button(size="small" plain round) {{ act.row.receiptFile ? 'Обновить' : 'Загрузить' }}

el-dialog(v-model="createActFormVisible")
  create-act-form(@created="handleActCreated")

el-dialog(v-model="updateActFormVisible")
  update-act-form(:act="updateActFormAct" @updated="handleActUpdated")
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import type { UploadFile } from 'element-plus';
import type { Act } from '@/models/documents';
import { useDocumentsStore } from '@/store/documents';

const documentsStore = useDocumentsStore();
const createActFormVisible = ref(false);
const updateActFormVisible = ref(false);
const updateActFormAct = ref<Act>({} as Act);
const actsLoading = ref(false);

const handleActCreate = () => {
  createActFormVisible.value = true;
}

const handleActUpdate = (act: Act) => {
  updateActFormAct.value = act;
  updateActFormVisible.value = true;
}

const handleSignedActUpload = (uploadFile: UploadFile, uuid: string) => {
  documentsStore.uploadSignedAct(uploadFile.raw as File, uuid)
    .then(() => {
      ElMessage.success('Акт загружен');
      documentsStore.getActs();
    });
}

const handleActReceiptUpload = (uploadFile: UploadFile, uuid: string) => {
  documentsStore.updateActReceipt(uploadFile.raw as File, uuid)
    .then(() => {
      ElMessage.success('Чек загружен');
      documentsStore.getActs();
    });
}

const handleActCreated = () => {
  ElMessage.success('Акт сгенерирован');
  createActFormVisible.value = false;
  updateActs();
}

const handleActUpdated = () => {
  ElMessage.success('Акт обновлен');
  updateActFormVisible.value = false;
  updateActs();
}

const actStatus = (act: Act) => act.signed ? act.confirmed ? 'подтвержден' : 'подписан' : 'не подписан';
const canUpdateAct = (act: Act) => !act.confirmed;
const canUploadReceipt = (act: Act) => !act.confirmed;

const STATUS_COLORS: { [key: string]: 'success' | 'primary' | 'danger' } = {
  'подтвержден': 'success',
  'подписан': 'primary',
  'не подписан': 'danger',
}

const updateActs = () => {
  actsLoading.value = true;
  documentsStore.getActs()
    .finally(() => actsLoading.value = false);
}

onMounted(() => updateActs());
</script>
