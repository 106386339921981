<template lang="pug">
el-form
  el-form-item(label="Период" required)
    el-date-picker(
      v-model="period"
      type="daterange"
      range-separator="—"
      value-format="x"
      format="DD.MM.YYYY"
      :clearable="false"
    )
  el-form-item(label="Сумма" required)
    el-input(v-model="amount" :formatter="amountFormatter")
  el-form-item(label="Наименование услуги" required)
    el-input(v-model="serviceName")
  el-form-item
    el-button(
      size="small"
      round
      plain
      @click="create"
    ) Создать
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import { useDocumentsStore } from '@/store/documents';

const documentsStore = useDocumentsStore();

const period = ref<[any, any]>([undefined, undefined]);
const amount = ref('');
const serviceName = ref('');

const emit = defineEmits(['created']);

const create = () => {
  const [start, end] = period.value;

  if (start === undefined || end === undefined) {
    ElMessage.error('Не задан период');
    return;
  }

  documentsStore.createAct(
    amount.value,
    serviceName.value,
    start / 1e3,
    end / 1e3,
  )
    .then(() => emit('created'));
}

const amountFormatter = (v: string) => {
  v = v.replaceAll(/^(\.)|([^\d\.])/g, '');
  v = v.replaceAll(/\.+/g, '.');

  const [i, d, ..._] = v.split('.');

  if (d) {
    v = [i, d].join('.');
  }

  if (d?.length > 2) {
    v = [i, d.substring(0, 2)].join('.');
  }

  return v;
}
</script>
