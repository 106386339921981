<template lang="pug">
el-menu.vh-100(
  :default-active="activeRouteIndex"
  :collapse="isCollapsed"
  :collapse-transition="false"
)
  el-button#collapse-btn(
    @click="isCollapsed = !isCollapsed"
    :icon="isCollapsed ? DArrowRight : DArrowLeft"
    plain
  )
  router-link(v-for="(item, index) in menu" :to="item.address" tag="div" :key="item.address")
    el-menu-item(:index="`${index}`" :key="index" v-if="checkIfAllowed(item)")
      component(:is="item.icon" style="height: 2em")
      el-button(
        v-if="!isCollapsed"
        style="margin-left: 1em;"
        link
        :class="{ underline: router.currentRoute.value.path === item.address }"
      ) {{ item.name }}
  el-card(v-if="!isCollapsed" class="auth-state")
    div.auth-state-body
      el-tooltip(content="Перейти в профиль" placement="top")
        el-space.pointer.underline(@click="router.push('/profile')")
          el-text {{ authStore.fullName }}
          el-icon: User
      el-button.logout(
        type="danger"
        size="small"
        plain
        @click="authStore.logout()"
      ) Выйти
      el-text.copyright © AI Diagnostic {{ new Date().getFullYear() }}
</template>

<script setup lang="ts">
import {
  List,
  Document,
  Link,
  User,
  DArrowLeft,
  DArrowRight,
  Histogram,
  Notification,
} from '@element-plus/icons-vue';
import { useAuthStore } from '@/store/auth';

const router = useRouter();
const isCollapsed = ref(false);
const loading = ref(true);
const authStore = useAuthStore();

type MenuItem = {
  name: string
  address: string
  icon: Component
  requiresAdmin: boolean
}

const menu: MenuItem[] = [
  {
    name: 'Задачи',
    address: '/',
    icon: List,
    requiresAdmin: false,
  },
  {
    name: 'Выгрузка результатов',
    address: '/annotation-results',
    icon: Link,
    requiresAdmin: true,
  },
  {
    name: 'Отчеты',
    address: '/reports',
    icon: Document,
    requiresAdmin: true,
  },
  {
    name: 'Статистика',
    address: '/stat',
    icon: Histogram,
    requiresAdmin: false,
  },
  {
    name: 'Уведомления',
    address: '/notifications',
    icon: Notification,
    requiresAdmin: false,
  },
];

const checkIfAllowed = (menuItem: MenuItem) => menuItem.requiresAdmin ? authStore.isAdmin : true

const activeRouteIndex = computed(() => {
  return menu.findIndex(item =>
      item.address === useRoute().path
  ).toString();
});

authStore.getCurrentUser().finally(() => loading.value = false);
</script>

<style lang="scss">
.underline {
  text-decoration: underline;
}

.pointer {
  cursor: pointer;
}

.auth-state {
  position: absolute;
  bottom: 0;
  width: 88%;
  padding-left: calc(var(--el-menu-base-level-padding) + var(--el-menu-level) * var(--el-menu-level-padding));

  .auth-state-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .copyright {
    align-self: flex-start;
  }
}

#collapse-btn {
  position: absolute;
  right: 0;
  top: 50%;
  width: 1%;
  transform: translate(50%, -50%);
}
</style>
