<template lang="pug">
el-card
  el-row(justify="space-between" :gutter="20")
    el-col(:span="12")
      el-descriptions(title="Рекзвизиты" border :column="1")
        el-descriptions-item(v-for="row in requisites" :label="row.label")
          el-row(style="flex-wrap: unset" justify="space-between")
            el-text(
              v-if="!row.edit"
              @click="row.edit = true"
            ) {{ row.value }}
            el-button(
              v-if="!row.edit"
              @click="row.edit = true"
              :icon="EditIcon"
              text
            )
            el-input(
              v-if="row.edit"
              v-model="row.value"
              :placeholder="row.actualValue || row.label"
            )
            el-button(
              v-if="row.edit"
              @click=`() => {
                row.edit = false;
                row.value = row.actualValue;
              }`
              :icon="CloseIcon"
              text
            )
            el-button(
              v-if="row.edit"
              @click="() => row.edit = false"
              :icon="CheckIcon"
              text
            )
      el-row(v-if="isRequisitesChanged" justify="end" style="margin-top: 10px;")
        el-button(
          size="small"
          plain
          round
          @click="updateRequisites"
        ) Сохранить

    el-col(:span="12")
      el-descriptions(title="Самозанятость" border :column="1")
        el-descriptions-item(label="Справка о получении статуса самозанятого")
          el-link(
            v-if="documentsStore.selfEmploymentCert"
            type="primary"
            :href="documentsStore.selfEmploymentCert.selfEmploymentCertFile.url"
          ) скачать
          el-upload(
            v-else
            ref="uploadRef"
            :show-file-list="false"
            :auto-upload="false"
            @change="handleSelfEmploymentCertUpload"
          )
            el-button(size="small" plain round) Загрузить
      br
      el-descriptions(title="Договор" border :column="1")
        el-descriptions-item(v-if="documentsStore.contract")
          template(v-slot:label) Договор ({{ contractSigned ? 'подписан' : 'не подписан' }})
          el-space
            el-link(type="primary" :href="documentsStore.contract.contractFile.url") скачать
            el-button(
              v-if="!documentsStore.contract?.signed"
              size="small"
              plain
              round
              @click="createContract"
            ) Создать новый
            el-upload(
              v-if="!contractSigned"
              ref="uploadRef"
              :show-file-list="false"
              :auto-upload="false"
              @change="handleContractUpload"
            )
              el-button(size="small" plain round) Загрузить с подписью
        el-descriptions-item(v-if="documentsStore.contract" label="Название договора")
          el-text {{ documentsStore.contract?.name }}
        el-descriptions-item(v-if="documentsStore.contract" label="Срок действия")
          el-text(v-if="documentsStore.contract?.validSince") c {{ documentsStore.contract?.validSince }}
          el-text(v-if="documentsStore.contract?.validUntil")  до {{ documentsStore.contract?.validUntil }}
        el-descriptions-item(v-if="!documentsStore.contract" label="Нет действующего договора")
          el-button(size="small" plain round @click="createContract") Создать новый
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus';
import type { UploadInstance, UploadFile } from 'element-plus';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Close as CloseIcon
} from '@element-plus/icons-vue';
import { useDocumentsStore } from '@/store/documents';

const documentsStore = useDocumentsStore();
const uploadRef = ref<UploadInstance>();
const contractSigned = computed(() => documentsStore.contract?.signed);

const handleSelfEmploymentCertUpload = (uploadFile: UploadFile) => {
  documentsStore.uploadSelfEmploymentCert(uploadFile.raw as File)
    .then(() => {
      ElMessage.success('Файл загружен');
      documentsStore.getCertificate();
    });
}

const handleContractUpload = (uploadFile: UploadFile) => {
  documentsStore.uploadContract(uploadFile.raw as File)
    .then(() => {
      ElMessage.success('Файл загружен');
      documentsStore.getContract();
    });
}

const createContract = () => {
  documentsStore.createContract()
    .then(() => {
      ElMessage.success('Договор сгенерирован');
      documentsStore.getContract();
    });
}

const _requisitesTemplate = { edit: false, actualValue: '', value: '' }

const requisites = ref({
  receiver: { label: 'ФИО получателя', ..._requisitesTemplate },
  passportNumber: { label: 'Серия и номер паспорта', ..._requisitesTemplate },
  passportIssuedBy: { label: 'Паспорт выдан', ..._requisitesTemplate },
  passportIssuerCode: { label: 'Код отделения', ..._requisitesTemplate },
  inn: { label: 'ИНН', ..._requisitesTemplate },
  bankAccountNumber: { label: 'Номер счета', ..._requisitesTemplate },
  bankIdentificationCode: { label: 'БИК', ..._requisitesTemplate },
  receiverBank: { label: 'Банк-получатель', ..._requisitesTemplate },
  correspondentAccount: { label: 'Корр. счет', ..._requisitesTemplate },
  bankInn: { label: 'ИНН банка', ..._requisitesTemplate },
  bankKpp: { label: 'КПП банка',  ..._requisitesTemplate },
  registrationAddress: { label: 'Адрес регистрации', ..._requisitesTemplate },
  email: { label: 'Email', ..._requisitesTemplate },
  phone: { label: 'Телефон', ..._requisitesTemplate },
});

const isRequisitesChanged = computed(() => {
  let changed = false;

  Object.values(requisites.value).forEach(v => {
    if (v.actualValue != v.value) {
      changed = true;
    }
  });

  return changed;
});

const initRequisites = () => {
  Object.entries(requisites.value).forEach(([k, v]) => {
    // @ts-ignore
    const actualValue = documentsStore.requisites[k] || '';

    const obj = { actualValue, value: actualValue, edit: false };

    // @ts-ignore
    requisites.value[k] = { ...v, ...obj };
  });
}

const updateRequisites = () => {
  const r = requisites.value;

  documentsStore.updateRequisites({
    receiver: r.receiver.value,
    passportNumber: r.passportNumber.value,
    passportIssuedBy: r.passportIssuedBy.value,
    passportIssuerCode: r.passportIssuerCode.value,
    inn: r.inn.value,
    bankAccountNumber: r.bankAccountNumber.value,
    bankIdentificationCode: r.bankIdentificationCode.value,
    receiverBank: r.receiverBank.value,
    correspondentAccount: r.correspondentAccount.value,
    bankInn: r.bankInn.value,
    bankKpp: r.bankKpp.value,
    registrationAddress: r.registrationAddress.value,
    email: r.email.value,
    phone: r.phone.value,
  })
    .then(() => {
      ElMessage.success('Реквизиты сохранены');
      initRequisites();
    });
}

const update = () => {
  documentsStore.getRequisites()
    .then(initRequisites);

  documentsStore.getDocuments();
}

onMounted(() => update());
</script>
