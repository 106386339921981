<template lang="pug">
el-card
  el-row(justify="end")
    el-button(
      round
      plain
      tag="a"
      :href="reportsStore.getExcelReportLink(params)"
    ) Экспорт в Excel
  el-table(
    :data="reportsStore.reports"
    v-loading="loading"
    @expand-change="handleExpand"
    @sort-change="setSortOrder"
  )
    el-table-column(type="expand" v-slot="report: { row: Report }")
      el-card(
        v-if="reportsStore.reportsByUserId[report.row.userId]?.performed.length"
        header="Разметка"
      )
        el-table(
          :data="reportsStore.reportsByUserId[report.row.userId]?.performed"
          v-loading="expandLoading"
        )
          el-table-column(label="UID" v-slot="entry: { row: ReportTaskEntry }")
            el-link(
              @click=`() => {
                taskDetails.taskUID = entry.row.taskUid
                taskDetails.show = true
              }`
            ) {{ entry.row.uid }}
          el-table-column(label="Статус" v-slot="entry: { row: ReportTaskEntry }")
            el-text(:type="statuses.textTypeByEntryStatus[entry.row.status]")
              | {{ statuses.entryStatusText[entry.row.status] }}
          el-table-column(label="Дата разметки" v-slot="entry: { row: ReportTaskEntry }")
            el-text {{ new Date((entry.row.performanceDatetime ?? 0) * 1e3).toLocaleDateString() }}
          el-table-column(label="Затраченное время" v-slot="entry: { row: ReportTaskEntry }")
            el-text
              | {{ Math.floor(entry.row.performanceTimeSpent / 3600) }} ч
              | {{ Math.floor(entry.row.performanceTimeSpent % 3600 / 60) }} м
          el-table-column(label="Оплата" v-slot="entry: { row: ReportTaskEntry }")
            component(
              :is="paymentIcon[entry.row.isPaid].component"
              :style="`height: 2em; color: ${paymentIcon[entry.row.isPaid].color};`"
            )
          el-table-column(v-slot="entry: { row: ReportTaskEntry }")
            el-button(
              round
              :disabled="!tasksStore.isActionAvailable(entry.row, Actions.PAYMENT)"
              @click="() => setPaid(entry.row, report.row.userId)"
            ) Оплатить
      el-card(
        v-if="reportsStore.reportsByUserId[report.row.userId]?.validated.length"
        header="Валидация"
      )
        el-table(
          :data="reportsStore.reportsByUserId[report.row.userId]?.validated"
          v-loading="expandLoading"
        )
          el-table-column(label="UID" v-slot="entry: { row: ReportTaskEntry }")
            el-link(
              @click=`() => {
                taskDetails.taskUID = entry.row.taskUid
                taskDetails.show = true
              }`
            ) {{ entry.row.uid }}
          el-table-column(label="Статус" v-slot="entry: { row: ReportTaskEntry }")
            el-text(:type="statuses.textTypeByEntryStatus[entry.row.status]")
              | {{ statuses.entryStatusText[entry.row.status] }}
          el-table-column(label="Дата валидации" v-slot="entry: { row: ReportTaskEntry }")
            el-text {{ new Date((entry.row.validationDatetime ?? 0) * 1e3).toLocaleDateString() }}
          el-table-column(label="Затраченное время" v-slot="entry: { row: ReportTaskEntry }")
            el-text
              | {{ Math.floor(entry.row.validationTimeSpent / 3600) }} ч
              | {{ Math.floor(entry.row.validationTimeSpent % 3600 / 60) }} м
          el-table-column(label="Оплата" v-slot="entry: { row: ReportTaskEntry }")
            component(
              :is="paymentIcon[entry.row.isValidationPaid].component"
              :style="`height: 2em; color: ${paymentIcon[entry.row.isValidationPaid].color};`"
            )
          el-table-column(v-slot="entry: { row: ReportTaskEntry }")
            el-button(
              round
              :disabled="!tasksStore.isActionAvailable(entry.row, Actions.VALIDATION_PAYMENT)"
              @click=`() => setValidationPaid(entry.row, report.row.userId)`
            ) Оплатить
    el-table-column(prop="user" label="Исполнитель")
    el-table-column(prop="entriesCount" label="Количество исследований")
    el-table-column(prop="amount" label="Сумма")
    el-table-column(prop="isPaid" label="Оплата" v-slot="report: { row: Report }")
      el-text(:type="textTypeByPaymentStatus[report.row.isPaid]") {{ paymentStatus[report.row.isPaid] }}
    el-table-column(v-slot="report: { row: Report }")
      el-button(
        round
        :disabled="!report.row.canBePaid"
        @click="() => setAllPaid(report.row)"
      ) Оплатить

  el-pagination.center(
    layout="total, prev, pager, next, sizes"
    :total="reportsStore.total"
    v-model:current-page="params.page"
    v-model:page-size="params.perPage"
    :page-sizes="[50, 100, 200, 300]"
    @current-change="update"
    @size-change="update"
  )

el-dialog(v-model="taskDetails.show" :align-center="true" width="75%" title="Задача")
  task-details(:taskUID="taskDetails.taskUID")
</template>

<script setup lang="ts">
import { CircleCheckFilled, CircleClose } from '@element-plus/icons-vue';
import { useTasksV2Store } from '@/store/tasks_v2'
import { useReportsV2Store } from '@/store/reports_v2'
import type { TaskEntry } from '@/models/tasks_v2'
import type { Report, ReportTaskEntry } from '@/models/reports'
import { Actions } from '@/utils/actions'
import TaskDetails from '@/components/TasksV2/TaskDetails.vue'
import * as statuses from '@/utils/statuses'

const tasksStore = useTasksV2Store()
const reportsStore = useReportsV2Store()
const loading = ref(false)
const expandLoading = ref(false)
const taskDetails = ref({
  taskUID: '',
  show: false,
})

const props = defineProps<{
  since: number
  to: number
}>()

const params = ref({
  page: 1,
  perPage: 100,
  since: props.since,
  to: props.to,
})

watch(
  () => props,
  (_) => update(),
  { deep: true }
)

const tableState = {
  expandedRowsCount: 0,
}

const paymentStatus = {
  false: 'Не оплачено',
  true: 'Оплачено',
}

const paymentIcon = {
  false: {
    component: CircleClose,
    color: 'var(--el-color-danger)',
  },
  true: {
    component: CircleCheckFilled,
    color: 'var(--el-color-success)',
  }
}

const textTypeByPaymentStatus = {
  false: 'danger',
  true: 'success',
}

const setAllPaid = (report: Report) => {
  const userId = report.userId

  loading.value = true

  reportsStore.getByUserId(userId, params.value)
    .then(() => {
      const promises: Promise<void>[] = []

      reportsStore.reportsByUserId[userId]?.performed.forEach(entry => {
        promises.push(
          tasksStore.setTaskEntryPaid(entry.taskUid, entry.uid)
        )
      })

      reportsStore.reportsByUserId[userId]?.validated.forEach(entry => {
        promises.push(
          tasksStore.setTaskEntryValidationPaid(entry.taskUid, entry.uid)
        )
      })

      return promises
    })
    .then(promises => {
      Promise.all(promises).finally(() => {
        loading.value = false
        update()
      })
    })
}

const setPaid = (entry: TaskEntry, userId: number) => {
  loading.value = true
  tasksStore.setTaskEntryPaid(entry.taskUid, entry.uid)
    .then(() => reportsStore.getByUserId(userId, params.value))
    .finally(() => loading.value = false)
}

const setValidationPaid = (entry: TaskEntry, userId: number) => {
  loading.value = true
  tasksStore.setTaskEntryValidationPaid(entry.taskUid, entry.uid)
    .then(() => reportsStore.getByUserId(userId, params.value))
    .finally(() => loading.value = false)
}

const handleExpand = (report: Report, expanded: TaskEntry[]) => {
  if (expanded.length < tableState.expandedRowsCount) {
    tableState.expandedRowsCount = expanded.length
    return
  }

  expandLoading.value = true

  reportsStore.getByUserId(report.userId, params.value)
    .finally(() => expandLoading.value = false)

  tableState.expandedRowsCount = expanded.length
}

const setSortOrder = () => {}

const update = () => {
  loading.value = true
  tableState.expandedRowsCount = 0

  params.value.since = props.since
  params.value.to = props.to

  reportsStore.get(params.value)
    .finally(() => loading.value = false)
}

update()
</script>
