<template lang="pug">
el-row.mt-2(type="flex" justify="space-around")
  el-card.login-card

    el-form(:model="credentials" :rules="rules" ref="form" v-loading="loading")
      el-row.mt-2(type="flex" justify="space-around")
        el-form-item
          b(style="font-size: 20px;") Авторизация

      el-row.mt-2(type="flex" justify="space-around")
        el-form-item(prop="username")
          el-input.w200(placeholder="Имя пользователя" @keydown.enter="submit" v-model="credentials.username")

      el-row(type="flex" justify="space-around")
        el-form-item(prop="password")
          el-input.w200(placeholder="Пароль" @keydown.enter="submit" v-model="credentials.password" show-password)

      el-row(type="flex" justify="space-around")
        el-form-item
          el-button(@click="submit" :disabled="localLoading") Войти


</template>

<script setup lang="ts">
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import type { FormInstance } from 'element-plus';

import { useAuthStore } from '../store/auth';

const authStore = useAuthStore();

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  }
});

const rules = {
  username: [
    { required: true, message: 'Введите имя пользователя', trigger: 'blur' }
  ],
  password: [
    { required: true, message: 'Введите пароль', trigger: 'blur' }
  ]
};

const form = ref<FormInstance>();
const localLoading = ref(false);

const credentials = ref({
  username: '',
  password: ''
});

const login = () => {
  localLoading.value = true;
  authStore.login(credentials.value.username, credentials.value.password)
      .catch(() => ElMessage.error('Произошла ошибка при попытке авторизации'))
      .finally(() => localLoading.value = false);
};

const submit = () => form.value?.validate(valid => valid ? login() : undefined);

</script>

<style scoped>
.login-card {
  width: 50%;
}

.w200 {
  width: 200px !important;
}

</style>
