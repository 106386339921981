<template lang="pug">
el-card(:header="props.taskUID")
    el-descriptions(:column="2" border)
        el-descriptions-item(label="Тип") {{ task?.type.name }}
        el-descriptions-item(label="Время создания") {{ new Date((task?.createdAt || 0) * 1e3).toLocaleString('ru-RU') }}
        el-descriptions-item(label="Статус")
            el-text(
                :type="task ? statuses.textTypeByTaskStatus[task.status] : undefined"
            ) {{ task && statuses.taskStatusText[task.status] }}
        el-descriptions-item(label="Описание") {{ task?.description || '—'}}
    el-row(
        v-if="tasksStore.isActionAvailable(task, Actions.REPLACE_FILE)"
        justify="end"
        style="margin-top:8px;"
    )
        el-upload(
            ref="replaceFileRef"
            :limit="1"
            :auto-upload="false"
            :on-change="(file) => handleReplaceFile(file)"
            @keydown.esc="handleCancel()"
        )
            el-button Заменить файл задачи

el-card(header="Элементы задачи")
    el-table(:data="tasksStore.entries" table-layout="auto")
        el-table-column(type="expand" v-slot="scope: { row: TaskEntry }")
            task-details-entry-expand(:entry="scope.row")
        el-table-column(type="index" label="#")
        el-table-column(prop="file" label="Файл" align="center" v-slot="scope")
            el-link(type="primary" :href="scope.row.file.url")
                el-text(type="primary") {{ scope.row.file.name.split('/').at(-1) }} ({{ filesize(scope.row.file.size) }})

        el-table-column(prop="result" label="Результат" align="center" v-slot="scope" v-if="task?.performer")
            el-link(type="primary" v-if="scope.row.result" :href="scope.row.result.url")
                el-text(
                    type="primary"
                ) {{ scope.row.result.name.split('/').at(-1) }} ({{ filesize(scope.row.result.size) }})

        el-table-column(prop="status" label="Статус" align="center" v-slot="scope: { row: TaskEntry }")
            el-text(
                :type="statuses.textTypeByEntryStatus[scope.row.status]"
            ) {{ statuses.entryStatusText[scope.row.status] }}

        el-table-column(align="center" v-slot="scope: { row: TaskEntry }")
            el-space
                defect-button(:entry="scope.row")
                validation-buttons(:entry="scope.row" @updated="updateTask(props.taskUID)")
        //- el-table-column(align="center")
        //-     el-button(@click="console.log('TODO')") чат

el-progress(
    v-if="uploadingInProgress"
    type="circle"
    class="el-progress-upload-file"
    :width="100"
    :stroke-width="10"
    :style="`z-index: ${elProgressZIndex};`"
    :percentage="uploadPercent"
    :status="uploadStatus"
)
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue'
import type { Ref } from 'vue'
import { filesize } from 'filesize'
import { ElLoading, ElMessage } from 'element-plus'
import type { UploadInstance, UploadFile } from 'element-plus'
import { useTasksV2Store } from '@/store/tasks_v2'
import type { Task, TaskEntry } from '@/models/tasks_v2'
import { Actions } from '@/utils/actions'
import * as statuses from '@/utils/statuses'
import TaskDetailsEntryExpand from '@/components/TasksV2/TaskDetailsEntryExpand.vue'
import DefectButton from '@/components/TasksV2/DefectButton.vue'
import ValidationButtons from '@/components/TasksV2/ValidationButtons.vue'

const props = defineProps<{
    taskUID: string
}>()

watch(
    () => props.taskUID,
    (taskUID: string) => update(),
)

const task = ref<Task>()
const replaceFileRef = ref<UploadInstance>()
const uploadRefs: {[key: string]: Ref<any>} = {}
const tasksStore = useTasksV2Store()
const uploadPercent = ref(0)
const uploadingInProgress = ref(false)
const elProgressZIndex = ref(2010)

let cancelUploading: (() => void) | undefined = undefined

const uploadStatus = computed((): 'success' | '' => {
    return uploadPercent.value === 100 ? 'success' : ''
})

const handleReplaceFile = (file: UploadFile) => {
    if (!task.value) {
        return
    }

    const loading = ElLoading.service({
        fullscreen: true,
        lock: true,
        svgViewBox: '0, 0, 0, 0',
    })

    elProgressZIndex.value = Number(loading.$el.style.zIndex) + 1
    uploadingInProgress.value = true

    const {promise, cancel} = tasksStore.replaceTaskFile(
        task.value.uid,
        file.raw!,
        (percent) => {uploadPercent.value = percent},
    )

    cancelUploading = cancel

    promise
        .then(() => {
            ElMessage.success('Файл отправлен')
            update()
        })
        .catch(() => {
            ElMessage.error('Ошибка при отправке файла')
        })
        .finally(() => {
            uploadingInProgress.value = false
            loading.close()
            uploadPercent.value = 0
            replaceFileRef.value?.clearFiles()
        })
}

const handleCancel = () => {
    cancelUploading && cancelUploading()
}

const updateTask = (uid: string) => {
    tasksStore.getTaskByUid(uid)
        .then((t: Task) => task.value = t)
}

const updateEntries = (uid: string) => {
    tasksStore.getTaskEntries(uid)
        .then((entries: TaskEntry[]) => {
            entries.forEach((e) => {
                if (!uploadRefs[e.uid]) {
                    uploadRefs[e.uid] = ref<UploadInstance>()
                }
            })
        })
}

const update = () => {
    updateTask(props.taskUID)
    updateEntries(props.taskUID)
}

update()
</script>

<style scoped>
.el-progress-upload-file {
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -50px;
    margin-top: -50px;
}
</style>
